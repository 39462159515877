import axios from "../utils/axios";
import { buildFilterQueryString } from "../utils/query";

export const getProvidersExpertise = async ({ offset, limit, filters } = {}) => {
  const filterQueryString = buildFilterQueryString(filters);
  let url = "/providers-expertise?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";
  url += filterQueryString;
  const { data } = await axios.get(url);
  return data;
};

export const removeProviderExpertise = async (providerExpertiseId) => {
  const { data: removedProviderExpertise } = await axios.delete(`/providers-expertise/${providerExpertiseId}`);
  return removedProviderExpertise;
};

export const addProviderExpertise = async (providerExpertise) => {
  const { data: addedProviderExpertise } = await axios.post(`/providers-expertise`, {
    data: providerExpertise,
  });
  return addedProviderExpertise;
};

export const updateProviderExpertise = async ({ id, ...providerExpertiseData }) => {
  const { data: updatedProviderExpertise } = await axios.put(`/providers-expertise/${id}`, {
    data: providerExpertiseData,
  });
  return updatedProviderExpertise;
};
