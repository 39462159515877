import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { AutocompleteStyled } from "./style/SelectAutocomplete.styled";

export default function Sizes({ id, label, onChange, fieldValue = null, fetchAction, autoWidthOnFocus, variant = "standard", isSelectMultiple, displayedValueMax = 3, error, required, helperText }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [isFocus, setIsFocus] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    const fetchedData = await fetchAction();
    setOptions(fetchedData);
    setIsLoading(false);
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  const toggleInput = () => {
    setIsOpen(!isOpen);
  };

  const onChangeInput = (event, values) => {
    onChange(id, values ? [values] : []);
  };

  const onBlurInput = () => {
    setIsFocus(false);
  };

  const onFocusInput = () => {
    setIsFocus(true);
  };

  return (
    <AutocompleteStyled
      onFocus={onFocusInput}
      onBlur={onBlurInput}
      value={fieldValue}
      onChange={onChangeInput}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      size="small"
      options={options}
      open={isOpen}
      onOpen={toggleInput}
      onClose={toggleInput}
      limitTags={displayedValueMax}
      rows={1}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={error}
          required={required}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
