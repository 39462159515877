import axios from "../utils/axios";

export const getAgencies = async ({ offset, limit } = {}) => {
  let url = "/agencies?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";

  const { data } = await axios.get(url);
  return data;
};

export const removeAgency = async (agencyId) => {
  const { data: removedAgency } = await axios.delete(`/agencies/${agencyId}`);
  return removedAgency;
};

export const addAgency = async (agency) => {
  const { data: addedAgency } = await axios.post(`/agencies`, {
    data: agency,
  });
  return addedAgency;
};

export const updateAgency = async ({ id, ...agencyData }) => {
  const { data: updatedAgency } = await axios.put(`/agencies/${id}`, {
    data: agencyData,
  });
  return updatedAgency;
};
