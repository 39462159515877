import { styled as muiStyled } from "@mui/material/styles";
import { Stack, Alert } from "@mui/material";

export const AlertContainer = muiStyled(Stack)(() => ({
  position: "absolute",
  left: 0,
  bottom: 0,
  width: "100%",
  zIndex: "10000",
}));

export const AlertStyled = muiStyled(Alert)(() => ({
  mb: 2,
  marginBottom: 0,
  boxShadow: "0 0 10px 0 rgba(0,0,0,0.3)",
}));
