import axios from "axios";

const getAccessToken = () => JSON.parse(localStorage.getItem("dpapp_key"));

const instance = axios.create({
  baseURL: process.env.REACT_APP_PROXY,
  headers: { Authorization: getAccessToken() },
});

instance.interceptors.request.use(
  function (config) {
    const accessToken = getAccessToken();
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    // if (error.response?.status === 401) {
    //   window.location.href = "/login";
    // }

    if (error.response?.data?.message) {
      return Promise.reject(new Error(error.response.data.message));
    } else {
      return Promise.reject(new Error(error));
    }
  }
);

export default instance;
