import axios from "../utils/axios";
import { buildFilterQueryString, buildSortingParams } from "../utils/query";

export const getProjects = async ({ offset, limit, filters = {}, sortModel } = {}) => {
  if (filters.isTopProject !== undefined && filters.isTopProject === false) {
    delete filters.isTopProject;
  }

  const filterQueryString = buildFilterQueryString(filters);
  const sortingParams = buildSortingParams(sortModel);
  const url = `/projects?limit=${limit}&offset=${offset}&${filterQueryString}${sortingParams}`;
  const { data: projects } = await axios.get(url);
  return projects;
};

export const removeProject = async (projectId) => {
  const { data: removedProject } = await axios.delete(`/projects/${projectId}`);
  return removedProject;
};

export const addProject = async (project) => {
  const { data: addedProjects } = await axios.post(`/projects`, { data: project });
  return addedProjects;
};

export const updateProject = async ({ id, ...project }) => {
  if (project.isTopProject !== undefined && project.isTopProject === false) {
    delete project.isTopProject;
  }

  const { data: updatedProject } = await axios.put(`/projects/${id}`, { data: project });
  return updatedProject;
};
