import axios from "../utils/axios";
import { buildFilterQueryString } from "../utils/query";

export const getSectors = async ({ offset, limit, filters } = {}) => {
  const filterQueryString = buildFilterQueryString(filters);
  let url = "/sectors?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";
  url += filterQueryString;
  const { data } = await axios.get(url);
  return data;
};

export const removeSector = async (sectorId) => {
  const { data: removedSector } = await axios.delete(`/sectors/${sectorId}`);
  return removedSector;
};

export const addSector = async (sector) => {
  const { data: addedSector } = await axios.post(`/sectors`, {
    data: sector,
  });
  return addedSector;
};

export const updateSector = async ({ id, ...sectorData }) => {
  const { data: updatedSector } = await axios.put(`/sectors/${id}`, {
    data: sectorData,
  });
  return updatedSector;
};
