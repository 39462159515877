export const getTextColorForBackground = (rgbString) => {
  const [r, g, b] = rgbString.match(/\d+/g).map(Number);
  const brightness = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));
  return brightness > 128 ? "#000" : "#fff";
};

export const getAverageColorFromBorders = (imageSrc) => {
  return new Promise((resolve) => {
    const imgElement = new Image();
    imgElement.crossOrigin = "Anonymous";
    imgElement.src = imageSrc;

    imgElement.onload = () => {
      try {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        canvas.width = imgElement.width;
        canvas.height = imgElement.height;

        context.drawImage(imgElement, 0, 0, imgElement.width, imgElement.height);

        const width = canvas.width;
        const height = canvas.height;

        const topPixels = context.getImageData(0, 0, width, 1).data;
        const bottomPixels = context.getImageData(0, height - 1, width, 1).data;
        const leftPixels = context.getImageData(0, 0, 1, height).data;
        const rightPixels = context.getImageData(width - 1, 0, 1, height).data;

        const pixels = [...topPixels, ...bottomPixels, ...leftPixels, ...rightPixels];

        let r = 0,
          g = 0,
          b = 0,
          count = 0;

        for (let i = 0; i < pixels.length; i += 4) {
          const alpha = pixels[i + 3];
          if (alpha === 0) {
            // Pixel transparent, considérer comme blanc
            r += 255;
            g += 255;
            b += 255;
          } else {
            r += pixels[i];
            g += pixels[i + 1];
            b += pixels[i + 2];
          }
          count++;
        }

        r = Math.floor(r / count);
        g = Math.floor(g / count);
        b = Math.floor(b / count);

        const averageColor = `rgb(${r}, ${g}, ${b})`;
        resolve(averageColor);
      } catch (err) {
        console.error("Erreur lors du traitement de l'image :", err);
        resolve("#161616");
      }
    };

    imgElement.onerror = () => {
      console.error("Erreur lors du chargement de l'image :", imageSrc);
      resolve("#161616");
    };
  });
};
