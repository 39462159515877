import React, { useState, useEffect } from "react";
import { Divider, List, ListItemIcon, ListItemText, Box, Typography } from "@mui/material";
import {
  Business as BusinessIcon,
  LocationCity as LocationCityIcon,
  DeveloperBoard as DeveloperBoardIcon,
  People as PeopleIcon,
  Hail as HailIcon,
  PermMedia as PermMediaIcon,
  Celebration as CelebrationIcon,
  Loyalty as LoyaltyIcon,
  Logout as LogoutIcon,
  ThumbUpAlt as ThumbUpIcon,
  BugReport as BugReportIcon,
} from "@mui/icons-material";
import { PageLink, Container, ContainerInner, Logo, ListItemStyled, SuperAdminLogo, ListStyled } from "./styles/DrawerLeft.styled";
import { useAuth } from "../../hooks/use-auth";
import { useLocation } from "react-router-dom";
import { roles } from "../../utils/roles";

export const views = [
  { name: "projects", menuName: "Projects", pathName: "/projects", menuIcon: () => <PermMediaIcon />, authorizedAccounts: [roles.ALL] },
  {
    name: "clients",
    menuName: "Clients",
    pathName: "/clients",
    menuIcon: () => <PeopleIcon />,
    authorizedAccounts: [roles.ADMIN, roles.SUPERADMIN, roles.SALE],
  },
  {
    name: "sales",
    menuName: "Sales",
    pathName: "/sales",
    menuIcon: () => <LoyaltyIcon />,
    authorizedAccounts: [roles.ADMIN, roles.SUPERADMIN],
  },
  {
    name: "sectors",
    menuName: "Sectors",
    pathName: "/sectors",
    menuIcon: () => <BusinessIcon />,
    authorizedAccounts: [roles.SUPERADMIN],
  },
  { name: "typologies", menuName: "Typologies", pathName: "/typologies", menuIcon: () => <DeveloperBoardIcon />, authorizedAccounts: [roles.SUPERADMIN] },
  { name: "celebrations", menuName: "Celebrations", pathName: "/celebrations", menuIcon: () => <CelebrationIcon />, authorizedAccounts: [roles.SUPERADMIN] },
  { name: "agencies", menuName: "Agencies", pathName: "/agencies", menuIcon: () => <LocationCityIcon />, authorizedAccounts: [roles.SUPERADMIN] },
  { name: "providers", menuName: "Providers", pathName: "/providers", menuIcon: () => <HailIcon />, authorizedAccounts: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN] },
  { name: "providers-expertise", menuName: "Providers Expertise", pathName: "/providers-expertise", menuIcon: () => <ThumbUpIcon />, authorizedAccounts: [roles.SUPERADMIN] },
];

export default function DrawerLeft() {
  const { isLogged, onLogout, getUserType, user } = useAuth();
  const [linkList, setLinkList] = useState(views);
  const location = useLocation();
  const accountType = user?.role;
  useEffect(() => {
    updateLinkList(location.pathname);
  }, [location.pathname]);

  const updateLinkList = (pathName) => {
    const updatedLinkList = linkList.map((link) => {
      if (link.pathName === pathName) {
        return { ...link, isSelected: true };
      } else {
        return { ...link, isSelected: false };
      }
    });
    setLinkList(updatedLinkList);
  };

  const AgencyLogo = () => {
    if (!user) return null;

    if (accountType === roles.SUPERADMIN) {
      return <SuperAdminLogo>SUPER ADMIN</SuperAdminLogo>;
    }

    return <Logo src={`${process.env.REACT_APP_PROXY}/assets/agencies/${user.imgPath}`} alt="Agency logo" />;
  };

  return (
    isLogged && (
      <Container>
        <ContainerInner>
          <AgencyLogo />
          <ListStyled sx={{ width: "100%" }}>
            {linkList.reduce((acc, v) => {
              const isDisplayed = v.authorizedAccounts.find((accountRoleName) => accountRoleName === accountType || accountRoleName === roles.ALL);
              if (isDisplayed) {
                return [
                  ...acc,
                  <PageLink to={`/${v.name}`} key={v.name} isselected={"" + !!v.isSelected}>
                    <ListItemStyled>
                      <ListItemIcon>{v.menuIcon()}</ListItemIcon>
                      <ListItemText primary={v.menuName} />
                    </ListItemStyled>
                  </PageLink>,
                ];
              }
              return acc;
            }, [])}
          </ListStyled>
          <Divider sx={{ marginTop: "auto" }} />
          <ListStyled>
            <ListItemStyled component="a" href="mailto:axelle@agence-digitalprod.com?subject=ORES PREVIEW APP // Suggestion // Bug&cc=margaux.b@agence-digitalprod.com">
              <ListItemIcon>
                <BugReportIcon />
              </ListItemIcon>
              <ListItemText primary="Report a bug" />
            </ListItemStyled>
            <ListItemStyled onClick={onLogout}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              <ListItemText primary="Logout" />
            </ListItemStyled>
          </ListStyled>
        </ContainerInner>
      </Container>
    )
  );
}
