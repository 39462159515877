import axios from "axios";

export const getHashedIds = async ({ previewIdList, agencyId, isAnonymous }) => {
  try {
    const { data: hashedPreviewId } = await axios.get(`${process.env.REACT_APP_PROXY}/previews/hash/${agencyId}/${previewIdList}?isAnonymous=${isAnonymous}`);
    return hashedPreviewId;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};

export const getPreviewLinkList = async (hashedPreviewIdList) => {
  try {
    const { data: previewLinkList } = await axios.get(`${process.env.REACT_APP_PROXY}/previews/projects?id=${hashedPreviewIdList}`);
    return previewLinkList;
  } catch (e) {
    return { error: { message: e.response.statusText, code: e.response.status } };
  }
};
