import axios from "../utils/axios";
import { buildFilterQueryString } from "../utils/query";

export const getProviders = async ({ offset, limit, filters } = {}) => {
  const filterQueryString = buildFilterQueryString(filters);
  let url = "/providers?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";
  url += filterQueryString;
  const { data } = await axios.get(url);
  return data;
};

export const removeProvider = async (providerId) => {
  const { data: removedProvider } = await axios.delete(`/providers/${providerId}`);
  return removedProvider;
};

export const addProvider = async (provider) => {
  const { data: addedProvider } = await axios.post("/providers", {
    data: provider,
  });
  return addedProvider;
};

export const updateProvider = async ({ id, ...providerData }) => {
  const { data: updatedProvider } = await axios.put(`/providers/${id}`, {
    data: providerData,
  });
  return updatedProvider;
};
