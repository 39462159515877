/* eslint-disable react/react-in-jsx-scope */
import { getClients, removeClient, addClient, updateClient } from "../services/clients";
import DataTable from "../components/Table/DataTable";
import Tooltip from "../components/Utils/Tooltip";
import { roles } from "../utils/roles";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    isRequired: true,
    helperText: "Please provide a name.",
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "visualBinary",
    headerName: "Visual",
    sortable: false,
    editable: false,
    isOnTable: false,
    type: "inputUpload",
    filterable: false,
    isOnFilter: false,
    isRequired: true,
  },
];

const entity = {
  tableId: "clients",
  label: "client",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
  create: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
  update: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
};

export default function ClientsPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getClients} addData={addClient} deleteData={removeClient} updateData={updateClient} headCells={headCells} />;
}
