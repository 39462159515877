import React, { useMemo } from "react";
import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import ClientsPage from "./pages/Clients";
import CelebrationsPage from "./pages/Celebrations";
import ProjectsPage from "./pages/Projects";
import SectorsPage from "./pages/Sectors";
import SalesPage from "./pages/Sales";
import TypologiesPage from "./pages/Typologies";
import LoginPage from "./pages/Login";
import PreviewsPage from "./pages/Previews";
import ProvidersPage from "./pages/Providers";
import AgencyPage from "./pages/Agencies";
import ProvidersExpertisePage from "./pages/ProvidersExpertise";
import { ProvideAuth, useAuth } from "./hooks/use-auth";
import ErrorPage from "./pages/404Error";
import useMediaQuery from "@mui/material/useMediaQuery";
/********************/
import DrawerLeft from "./components/UI/DrawerLeft";
import FeedbackBandeau from "./components/Alert/AlertBandeau";
import PatchNotePopin from "./components/Popin/PatchNote";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { BackgroundApp, Container } from "./styles/App.styled";

export default function App() {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const theme = useMemo(
    () =>
      createTheme({
        typography: {
          fontFamily: "Montserrat, sans-serif",
        },
        palette: {
          primary: { main: "#282828" },
        },
      }),
    [prefersDarkMode]
  );

  const { isLogged, onLogout } = useAuth();

  const ProtectedRoute = ({ children }) => {
    if (!isLogged) {
      return <Navigate to="/" replace />;
    }

    return children;
  };

  return (
    <BackgroundApp>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <DrawerLeft />
        <Container component="main">
          {/* <ProvideAuth> */}
          <FeedbackBandeau />
          <Routes>
            <Route path="/previews" element={<PreviewsPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/projects"
              element={
                <ProtectedRoute>
                  <ProjectsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/clients"
              element={
                <ProtectedRoute>
                  <ClientsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/celebrations"
              element={
                <ProtectedRoute>
                  <CelebrationsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sectors"
              element={
                <ProtectedRoute>
                  <SectorsPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/sales"
              element={
                <ProtectedRoute>
                  <SalesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/typologies"
              element={
                <ProtectedRoute>
                  <TypologiesPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/agencies"
              element={
                <ProtectedRoute>
                  <AgencyPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/providers"
              element={
                <ProtectedRoute>
                  <ProvidersPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/providers-expertise"
              element={
                <ProtectedRoute>
                  <ProvidersExpertisePage />
                </ProtectedRoute>
              }
            />
            <Route path="*" element={<ErrorPage />}></Route>
          </Routes>
          {/* </ProvideAuth> */}
        </Container>
      </ThemeProvider>
      {/* </Box> */}
    </BackgroundApp>
  );
}
