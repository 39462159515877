/* eslint-disable react/react-in-jsx-scope */
import DataTable from "../components/Table/DataTable";
import Tooltip from "../components/Utils/Tooltip";
import { getCelebrations, removeCelebration, addCelebration, updateCelebration } from "../services/celebrations";
import { roles } from "../utils/roles";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    isRequired: true,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
];

const entity = {
  tableId: "celebrations",
  label: "celebration",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.SUPERADMIN],
  create: [roles.SUPERADMIN],
  update: [roles.SUPERADMIN],
};

export default function CelebrationsPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getCelebrations} addData={addCelebration} deleteData={removeCelebration} updateData={updateCelebration} headCells={headCells} />;
}
