import React from "react";
import { getProjects, removeProject, addProject, updateProject } from "../services/projects";
import { getClients } from "../services/clients";
import { getCelebrations } from "../services/celebrations";
import { getSectors } from "../services/sectors";
import { getTypologies } from "../services/typologies";
import { getSales } from "../services/sales";
import { getProviders } from "../services/providers";
import DataTable from "../components/Table/DataTable";
import Tooltip from "../components/Utils/Tooltip";
import { DeveloperBoard as DeveloperBoardIcon } from "@mui/icons-material";
import { getAgencies } from "../services/agencies";
import { roles } from "../utils/roles";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    minWidth: 300,
    isRequired: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    icon: () => <DeveloperBoardIcon />,
  },
  {
    field: "previewLink",
    headerName: "Preview link",
    sortable: false,
    editable: false,
    isOnTable: false,
    cellType: "actions",
    type: "textField",
    flex: 1,
    actionType: "link",
    isRequired: true,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    helperText: "Please provide a valid link.",
  },
  {
    field: "clients",
    headerName: "Client",
    sortable: true,
    editable: false,
    isOnTable: true,
    isRequired: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.clients?.map((c) => c.name).join(", "),
    fetchAction: async () => await getClients(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "sectors",
    headerName: "Sectors",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.sectors?.map((s) => s.name).join(", "),
    fetchAction: async () => await getSectors(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "typologies",
    headerName: "Typologies",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.typologies?.map((t) => t.name).join(", "),
    fetchAction: async () => await getTypologies(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "celebrations",
    headerName: "Celebration",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.celebrations?.map((c) => c.name).join(", "),
    fetchAction: async () => await getCelebrations(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "sales",
    headerName: "Sale",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: false,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: true,
    valueGetter: (params) => params.row.sales?.[0]?.name,
    fetchAction: async () => await getSales(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "providers",
    headerName: "Providers",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: true,
    valueGetter: (params) => params.row.providers?.map((s) => s.name).join(", "),
    fetchAction: async () => await getProviders(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "agencies",
    headerName: "Agency",
    sortable: true,
    editable: false,
    isOnTable: true,
    isRequired: true,
    isOnEditPopin: false,
    isSuperAdminEditOnly: true,
    type: "selectAutocomplete",
    isSelectMultiple: false,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: true,
    valueGetter: (params) => params.row.agencies?.[0]?.name,
    fetchAction: async () => await getAgencies(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "date",
    headerName: "Date",
    sortable: true,
    editable: false,
    isOnTable: false,
    type: "dateTime",
    isOnFilter: true,
    valueGetter: (params) => {
      return params.row.date?.split("T")[0] || "";
    },
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "canShare",
    headerName: "Shareable",
    sortable: true,
    editable: false,
    isOnTable: false,
    type: "checkbox",
    isDefaultChecked: true,
    valueGetter: (params) => params.row.canShare,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "isTopProject",
    headerName: "Top project",
    sortable: true,
    editable: false,
    isOnTable: false,
    type: "checkbox",
    isOnFilter: true,
    isDefaultChecked: false,
    cellType: "actions",
    valueGetter: (params) => params.row.isTopProject,
  },
];

const entity = {
  tableId: "projects",
  label: "project",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.ALL],
  create: [roles.ALL],
  update: [roles.ALL],
};

export default function ProjectsPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getProjects} addData={addProject} deleteData={removeProject} updateData={updateProject} headCells={headCells} />;
}
