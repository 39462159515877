import axios from "../utils/axios";
import { buildFilterQueryString } from "../utils/query";

export const getSales = async ({ offset, limit, filters } = {}) => {
  const filterQueryString = buildFilterQueryString(filters);
  let url = "/sales?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";
  url += filterQueryString;
  const { data } = await axios.get(url);
  return data;
};

export const removeSale = async (saleId) => {
  const { data: removedSale } = await axios.delete(`/sales/${saleId}`);
  return removedSale;
};

export const addSale = async (sale) => {
  const { data: addedSale } = await axios.post(`/sales`, {
    data: sale,
  });
  return addedSale;
};

export const updateSale = async ({ id, ...saleData }) => {
  const { data: updatedSale } = await axios.put(`/sales/${id}`, {
    data: saleData,
  });
  return updatedSale;
};
