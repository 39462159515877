/* eslint-disable react/react-in-jsx-scope */
import { getTypologies, removeTypology, addTypology, updateTypology } from "../services/typologies";
import DataTable from "../components/Table/DataTable";
import { roles } from "../utils/roles";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    isRequired: true,
    valueGetter: (params) => params.row.name,
  },
];

const entity = {
  tableId: "typologies",
  label: "typology",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.SUPERADMIN],
  create: [roles.SUPERADMIN],
  update: [roles.SUPERADMIN],
};

export default function TypologiesPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getTypologies} addData={addTypology} deleteData={removeTypology} updateData={updateTypology} headCells={headCells} />;
}
