import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { Box } from "@mui/material";

export const Container = styled(Box)`
  background-color: rgba(40, 40, 40, 0.95);
  min-height: 100vh;
`;

export const Logo = styled.img`
  max-width: 300px;
  width: 100%;
  margin-bottom: 8px;
`;

export const ProjectVisual = styled.div`
  /* background-image: ${({ visualPath }) => (visualPath ? `url(${visualPath})` : "none")};
  background-color: #fff;
  /* background-color: ${({ $backgroundColor }) => $backgroundColor || "#282828"}; */
  /* background-size: 100px;
  background-position: center 80%;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  transition: 0.3s;  */
  position: absolute;
  left: 0;
  width: 100%;
  bottom: 0;
  padding: 16px;
`;

export const ContainerText = styled(Box)`
  /* padding: 16px; */
  /* position: absolute;
  top: 0;
  left: 0; */
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding-bottom: 120px;
`;

export const Visual = styled.img`
  margin-inline: auto;
  display: block;
  margin-top: 16px;
  height: 100px;
  width: 200px;
  object-fit: contain;
  transition: 0.3s;
`;

export const ProjectLink = styled.a`
  padding: 16px;
  background-color: #fff;
  border: solid 1px #fff;
  width: 100%;
  color: #000;
  /* color: ${({ $color }) => $color || "#fff"}; */
  position: relative;
  /* aspect-ratio: 16 / 9; */
  transition: 0.3s;
  text-decoration: none;
  cursor: pointer;
  overflow: hidden;
  border: solid 1px #fff;
  border-radius: 8px;
  max-width: 400px;
  min-width: 300px;
  width: 100%;
  /* background-image: ${({ visualPath }) => (visualPath ? `url(${visualPath})` : "none")};
  background-color: #fff;
  background-size: 100px;
  background-position: center 70%;
  background-repeat: no-repeat; */
  /* padding-bottom: 140px; */

  &:hover {
    ${Visual} {
      transform: scale(1.1);
    }
  }
`;

export const ContainerProjects = muiStyled(Box)({});

export const LogoAgency = styled.img`
  transition: 0.3s;
  object-fit: contain;
  &:hover {
    filter: opacity(0.5);
  }
`;
