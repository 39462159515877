import styled from "styled-components";
import { styled as styledMui } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { visuallyHidden } from "../../styles/Common.styled";

export const Logo = styled.img`
  width: 200px;
  margin: 0 auto;
  display: block;
  margin-bottom: 8px;
`;

export const Form = styled.form`
  background-color: #fff;
  padding: 50px;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 0 15px 0px rgb(0 0 0 / 10%);
  padding-top: 8px;
`;

export const LoginButton = styled(Button)`
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  margin-top: 20px;
  background-color: #282828;
`;

export const Title = styledMui(Typography)`
  ${visuallyHidden}
`;
