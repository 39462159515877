import { styled as muiStyled } from "@mui/material/styles";
import { DataGrid } from "@mui/x-data-grid";
import styled from "styled-components";

export const StyledDataGrid = muiStyled(DataGrid)(() => ({
  padding: "10px",
  letterSpacing: "0.05em",
  borderTopLeftRadius: 0,
  borderTopRightRadius: 0,
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
    width: "5px",
  },
  "& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "5px",
  },
  "& .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
  "& .MuiCheckbox-root.Mui-disabled": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: 700,
  },
  "& .MuiDataGrid-cell[data-field='actions']": {
    justifyContent: "flex-end",
  },
}));

export const ContainerDataGrid = styled.div`
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  & .container-preview-img {
    & .LowerContainer {
      display: none;
    }
  }
`;

export const ContainerToolbarItems = styled.div`
  width: 100%;
`;
