import axios from "../utils/axios";

export const getSearches = async () => {
  const { data: searches } = await axios.get(`/searches`);
  return searches;
};

export const removeSearchById = async (searchId) => {
  const { data: removedSearch } = await axios.delete(`/searches/${searchId}`);
  return removedSearch;
};

export const addSearch = async (search) => {
  const { data: addedSearches } = await axios.post(`/searches`, { data: search });
  return addedSearches;
};

export const updateSearchTitle = async (searchId, searchTitle) => {
  const { data: updatedSearch } = await axios.put(`/searches/${searchId}`, { data: searchTitle });
  return updatedSearch;
};
