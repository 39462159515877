import React, { useEffect } from "react";
import { AlertStyled, AlertContainer } from "./styles/AlertBandeau.styled";
import { Slide } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { hideFeedback } from "../../app/feedbackSlice";
export const ALERT_VARIANTS = { success: "success", error: "error", warning: "warning", info: "info" };

export default function AlertBandeau() {
  const { isOpen, variant, message } = useSelector((state) => state.feedback);
  const dispatch = useDispatch();

  const closeFeedback = () => dispatch(hideFeedback());

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        closeFeedback();
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [isOpen, message]);

  return (
    <Slide direction="up" in={isOpen} mountOnEnter unmountOnExit>
      <AlertContainer spacing={2} p={2}>
        <AlertStyled severity={variant} onClose={closeFeedback}>
          {message}
        </AlertStyled>
      </AlertContainer>
    </Slide>
  );
}
