import axios from "../utils/axios";

export const getUserById = async (userId) => {
  const { data: user } = await axios.get(`/users/${userId}`);
  return user;
};

export const getUsers = async () => {
  const { data: user } = await axios.get(`/users`);
  return user;
};
