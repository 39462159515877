import React, { useState, useEffect, forwardRef, useRef } from "react";
import { Button, Stack } from "@mui/material/";
import { Dialog, Divider } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import SelectAutocompleteUnique from "../Input/SelectAutocompleteUnique";
import DatePicker from "../Input/DatePicker";
import InputCheckbox from "../Input/Checkbox";
import { VisuallyHiddenInput, ImagePreview, ImagePreviewContainer } from "./style/Popin.styled";
import { CloudUpload as CloudUploadIcon, Delete as DeleteIcon, FileDownload } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";
import { useAuth } from "../../hooks/use-auth";
import { roles } from "../../utils/roles";
import { getAverageColorFromBorders } from "../../utils/functions";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({ tableId, isOpen, action, handleClose, title, headCells, itemData = {}, deleteData }) {
  const [formData, setFormData] = useState({});
  const [formDataRequired, setFormDataRequired] = useState([]);
  const [previewUrl, setPreviewUrl] = useState("");
  const [visualBackgroundColor, setVisualBackgroundColor] = useState("#fff");
  const { isLogged, onLogout, getUserType, user } = useAuth();
  const inputRef = useRef(null);
  const ref = useRef(null);
  const fileInputRef = useRef(null);
  const dispatch = useDispatch();

  // const setCurrentVisualBackground = async () => {
  //   if (!previewUrl) return;
  //   const color = await getAverageColorFromBorders(previewUrl);
  //   setVisualBackgroundColor(color);
  // };

  // useEffect(() => {
  //   setCurrentVisualBackground();
  // }, [previewUrl]);

  useEffect(() => {
    if (isOpen) {
      const requiredInputs = headCells.filter((cell) => cell.isRequired && (cell.isSuperAdminEditOnly ? user.role === roles.SUPERADMIN : true));
      if (itemData.visualPath) {
        const pathUrls = { clients: "/api/assets/clients/" };
        const visualUrl = `${pathUrls[tableId]}${itemData.visualPath}`;
        setPreviewUrl(visualUrl);
      }
      setFormData({
        ...itemData,
        canShare: itemData.canShare === null ? true : itemData.canShare,
        date: itemData.date === null ? new Date() : itemData.date,
      });

      setFormDataRequired(requiredInputs);
      inputRef.current?.focus();
    } else {
      setFormData({});
      setFormDataRequired([]);
      setPreviewUrl("");
      setVisualBackgroundColor("#fff");
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
    }
  }, [isOpen]);

  const onChangeSelectInput = (inputId, values) => {
    setFormData({ ...formData, [inputId]: values });
  };

  const onChangeInput = (inputId, { target: { value } }) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeFile = async (inputId, event) => {
    const file = event.target.files?.[0];

    // setVisualBackgroundColor

    const color = await getAverageColorFromBorders(file);
    setVisualBackgroundColor(color);

    console.log(file, inputId);
    if (file) {
      setFormData((prev) => ({ ...prev, [inputId]: file }));

      if (previewUrl) {
        URL.revokeObjectURL(previewUrl);
      }

      const newFileUrl = URL.createObjectURL(file);
      console.log("newFileUrl", newFileUrl);
      setPreviewUrl(newFileUrl);
    }
  };

  // const onDeleteFile = (inputId) => {
  //   setFormData({ ...formData, [inputId]: null });
  //   setPreviewUrl(null);
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = null;
  //   }
  // };

  const onChangeInputDate = (inputId, value) => {
    setFormData({ ...formData, [inputId]: value });
  };

  const onChangeCheckbox = (inputId, isChecked) => {
    setFormData({ ...formData, [inputId]: isChecked });
  };

  const getFormattedPreviewLink = (previewLink) => {
    if (!/https:\/\//g.test(previewLink)) {
      return "https://" + previewLink.replace(/\s/g, "");
    }
    return previewLink.replace(/\s/g, "");
  };

  const actionPopin = () => {
    const updatedRequiredInputData = formDataRequired.map((requiredData) => {
      const hasValue = formData[requiredData.field] && /\w+/g.test(formData[requiredData.field]);
      console.log(formData[requiredData.field], hasValue);
      if (hasValue) {
        return { ...requiredData, hasError: false };
      } else {
        return { ...requiredData, hasError: true };
      }
    });
    const hasError = updatedRequiredInputData.find((requiredInput) => requiredInput.hasError);
    console.log("updatedRequiredInputData", updatedRequiredInputData);
    setFormDataRequired(updatedRequiredInputData);

    const fieldListWithError = updatedRequiredInputData.filter((field) => field.hasError).map(({ headerName }) => headerName);

    if (hasError) {
      dispatch(displayErrorFeedback({ message: "Please fill all required fields : " + fieldListWithError.join(", ") }));
    } else {
      action(formData);
      handleClose();
    }
  };

  const deleteItem = () => {
    deleteData(itemData.id);
    handleClose();
  };

  const formatInput = (inputId, { target: { value } }, b) => {
    if (inputId === "previewLink" && /\w+/g.test(value)) {
      const updatedLink = getFormattedPreviewLink(value);
      setFormData({ ...formData, [inputId]: updatedLink });
    }
  };

  const displayFields = () => {
    return headCells.map((field, index) => {
      const hasFormError = !!formDataRequired.find((dataRequired) => dataRequired.field === field.field && dataRequired.hasError);
      const { isSelectMultiple, displayedValueMax, isSuperAdminEditOnly } = field;

      if (field.type === "textField") {
        return (
          <TextField
            sx={{ display: "block", padding: "5px 0" }}
            fullWidth
            key={field.field}
            required={!!field.isRequired}
            value={formData[field.field] || ""}
            id={field.field}
            label={field.headerName}
            variant="standard"
            onChange={onChangeInput.bind(this, field.field)}
            error={hasFormError}
            onBlur={formatInput.bind(this, field.field)}
            helperText={hasFormError ? field.helperText || "" : ""}
            onKeyDown={(e) => {
              const ENTER = 13;
              if (e.keyCode === ENTER) {
                e.preventDefault();
              }
            }}
            inputRef={index === 0 ? inputRef : null}
          />
        );
      } else if (field.type === "inputUpload") {
        return (
          <Stack spacing={1} direction="column" alignItems="center" width="fit-content" margin="0 auto" key={field.field}>
            <ImagePreviewContainer $backgroundColor={visualBackgroundColor}>{!!previewUrl && <ImagePreview key={previewUrl} src={previewUrl} alt="" />}</ImagePreviewContainer>
            <Stack direction="row" justifyContent="center" alignItems="center" width="100%" maxWidth={300} gap={2}>
              {/* {!!previewUrl && (
                <Button variant="contained" color="error" size="small" startIcon={<DeleteIcon />} onClick={onDeleteFile}>
                  Delete
                </Button>
              )} */}
              <Button component="label" role={undefined} variant="contained" tabIndex={-1} startIcon={<CloudUploadIcon />}>
                Upload files
                <VisuallyHiddenInput type="file" ref={fileInputRef} onChange={onChangeFile.bind(this, field.field)} />
              </Button>
            </Stack>
          </Stack>
        );
      } else if (field.type === "textFieldNumber") {
        return (
          <TextField
            sx={{ display: "block", padding: "5px 0" }}
            fullWidth
            key={field.field}
            required={!!field.isRequired}
            value={formData[field.field] || null}
            id={field.field}
            label={field.headerName}
            variant="standard"
            onChange={onChangeInput.bind(this, field.field)}
            error={hasFormError}
            helperText={hasFormError ? field.helperText || "" : ""}
            onBlur={formatInput.bind(this, field.field)}
            type="number"
          />
        );
      } else if (field.type === "selectAutocomplete") {
        // const fieldValue = isSelectMultiple ? formData[field.field] : formData[field.field]?.[0] ?? null;

        if (isSuperAdminEditOnly && user.role !== roles.SUPERADMIN) return null;

        if (isSelectMultiple) {
          return (
            <div key={field.field} style={{ padding: "5px 0" }}>
              <SelectAutocomplete
                error={hasFormError}
                required={!!field.isRequired}
                helperText={hasFormError ? field.helperText || "" : ""}
                id={field.field}
                label={field.headerName}
                onChange={onChangeSelectInput}
                fetchAction={field.fetchAction}
                fieldValue={formData[field.field]}
                isSelectMultiple={isSelectMultiple}
                displayedValueMax={displayedValueMax}
              />
            </div>
          );
        } else {
          return (
            <div key={field.field} style={{ padding: "5px 0" }}>
              <SelectAutocompleteUnique
                error={hasFormError}
                required={!!field.isRequired}
                helperText={hasFormError ? field.helperText || "" : ""}
                id={field.field}
                label={field.headerName}
                onChange={onChangeSelectInput}
                fetchAction={field.fetchAction}
                fieldValue={formData[field.field]?.[0]}
                isSelectMultiple={isSelectMultiple}
                displayedValueMax={displayedValueMax}
              />
            </div>
          );
        }
      } else if (field.type === "dateTime") {
        return (
          <div key={field.field} style={{ padding: "5px 0" }}>
            <DatePicker id={field.field} onChange={onChangeInputDate} dateValue={formData[field.field]} />
          </div>
        );
      } else if (field.type === "checkbox") {
        const checked = formData[field.field];
        return (
          <div key={field.field} style={{ padding: "5px 0" }}>
            <InputCheckbox sx={{ display: "block", padding: "5px 0" }} id={field.field} isInputChecked={checked} onChange={onChangeCheckbox} label={field.headerName} />
          </div>
        );
      }
    });
  };

  const DeleteButton = !title?.startsWith("Add") && (
    <Button variant="contained" color="error" onClick={deleteItem} size="small">
      Delete
    </Button>
  );

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
      fullWidth={true}
      maxWidth="sm"
      onKeyUp={(e) => {
        e.preventDefault();
      }}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off" ref={ref}>
          {displayFields()}
        </Box>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
          {DeleteButton}
          <Stack spacing={1} direction="row" marginLeft="auto">
            <Button variant="contained" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="success" onClick={actionPopin} aria>
              Validate
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
