import { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { AutocompleteStyled } from "./style/SelectAutocomplete.styled";

export default function Sizes({ id, label, onChange, fieldValue = [], fetchAction, autoWidthOnFocus, variant = "standard", error, required, helperText }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchData = async () => {
    setIsLoading(true);
    const fetchedData = await fetchAction();
    setOptions(fetchedData);
    setIsLoading(false);
  };

  useEffect(() => {
    isOpen && fetchData();
  }, [isOpen]);

  const toggleInput = () => {
    setIsOpen(!isOpen);
  };

  const onChangeInput = (event, values) => {
    onChange(id, values);
  };

  return (
    <AutocompleteStyled
      multiple={true}
      value={fieldValue}
      onChange={onChangeInput}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      size="small"
      options={options}
      open={isOpen}
      onOpen={toggleInput}
      onClose={toggleInput}
      limitTags={1}
      rows={1}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant={variant}
          error={error}
          required={required}
          helperText={helperText}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}
