import axios from "../utils/axios";

export const getClients = async ({ offset, limit } = {}) => {
  let url = "/clients?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";

  const { data } = await axios.get(url);
  return data;
};

export const removeClient = async (clientId) => {
  const { data: removedClient } = await axios.delete(`/clients/${clientId}`);
  return removedClient;
};

export const addClient = async ({ visualBinary, ...client }) => {
  const formData = new FormData();
  formData.append("data", JSON.stringify(client));
  formData.append("file", visualBinary);

  const { data: addedClient } = await axios.post(`/clients`, formData);

  return addedClient;
};

export const updateClient = async ({ id, visualBinary, ...client }) => {
  console.log("{ id, visualBinary, ...client }", { id, visualBinary, ...client });
  const formData = new FormData();
  formData.append("data", JSON.stringify(client));
  formData.append("file", visualBinary);

  const { data: updatedClient } = await axios.put(`/clients/${id}`, formData);

  return updatedClient;
};
