export const objectToQueryString = (obj) => {
  const queryString = Object.entries(obj).reduce((accumulator, [key, value]) => {
    if (value === null || value === undefined) {
      return accumulator;
    }

    const encodedKey = encodeURIComponent(key);
    const encodedValue = encodeURIComponent(value);
    const keyValueString = `${encodedKey}=${encodedValue}`;

    return accumulator ? `${accumulator}&${keyValueString}` : keyValueString;
  }, "");

  return queryString ? `?${queryString}` : "";
};

const formatFilterValues = (key, values) => {
  return values.reduce(
    ({ idList, nameList }, value) => {
      if (value.isTextSearch) {
        nameList.push(value.name);
      } else {
        idList.push(value.id);
      }
      return { idList, nameList };
    },
    { idList: [], nameList: [] }
  );
};

export const buildFilterQueryString = (filters) => {
  if (filters === undefined) return "";

  const filterList = Object.entries(filters).reduce((acc, [key, values]) => {
    if (values !== undefined && values !== null) {
      if (Array.isArray(values)) {
        const { idList, nameList } = formatFilterValues(key, values);
        acc.push(...[idList.length ? `${key}=${idList.join(",")}` : "", nameList.length ? `${key}Name=${nameList.join(",")}` : ""].filter(Boolean));
      } else if (typeof values === "boolean") {
        acc.push(`${key}=${values}`);
      } else {
        acc.push(`${key}=${values}`);
      }
    }
    return acc;
  }, []);

  return filterList.length ? `${filterList.join("&")}` : "";
};

export const buildSortingParams = (sortModel) => {
  return sortModel ? `sortField=${sortModel.field}&sortDirection=${sortModel.sort}` : "";
};

export const handleRequestError = (error) => {
  const statusText = error.response?.statusText || "Unknown Error";
  const statusCode = error.response?.status || "No Status Code";
  return { error: { message: statusText, code: statusCode } };
};
