/* eslint-disable react/react-in-jsx-scope */
import { getSales, removeSale, addSale, updateSale } from "../services/sales";
import DataTable from "../components/Table/DataTable";
import Tooltip from "../components/Utils/Tooltip";
import { roles } from "../utils/roles";
import { getAgencies } from "../services/agencies";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    isRequired: true,
    helperText: "Please provide a name.",
  },
  {
    field: "agencies",
    headerName: "Agency",
    sortable: true,
    editable: false,
    isOnTable: false,
    isOnEditPopin: false,
    isSuperAdminEditOnly: true,
    type: "selectAutocomplete",
    isSelectMultiple: false,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: false,
    valueGetter: (params) => params.row.agencies?.[0]?.name,
    fetchAction: async () => await getAgencies(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
];

const entity = {
  tableId: "sales",
  label: "sale",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.ADMIN, roles.SUPERADMIN],
  create: [roles.ADMIN, roles.SUPERADMIN],
  update: [roles.ADMIN, roles.SUPERADMIN],
};

export default function SalesPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getSales} addData={addSale} deleteData={removeSale} updateData={updateSale} headCells={headCells} />;
}
