import { Link } from "react-router-dom";
import { styled as muiStyled } from "@mui/material/styles";
import { Drawer, ListItemButton, Box, List } from "@mui/material";
import styled from "styled-components";

// export const Container = muiStyled(Drawer)(() => ({
//   width: 100,
//   flexShrink: 0,
//   height: "100%",
//   backgroundColor: "#282828",
//   "& .MuiDrawer-paper": {
//     width: 100,
//     boxSizing: "border-box",
//     backgroundColor: "#282828",
//     alignItems: "center",
//     justifyContent: "flex-start",
//     overflowY: "auto",
//     position: "fixed",
//     left: 0,
//     top: 0,
//     height: "100vh",
//   },
//   "& .MuiDivider-root": {
//     width: "100%",
//   },
//   "& .MuiListItemText-root": {
//     color: "#fff",
//     textAlign: "center",
//   },
//   "& .MuiListItemIcon-root": {
//     color: "#fff",
//     display: "block",
//     textAlign: "center",
//   },
//   "& .MuiTypography-root": {
//     fontSize: "0.8rem",
//   },
//   "& .MuiList-root": {
//     padding: 0,
//   },
//   "& ::-webkit-scrollbar": {
//     width: "5px",
//   },
//   "& ::-webkit-scrollbar-thumb": {
//     background: "#888",
//     borderRadius: "5px",
//   },
// }));

export const Container = styled(Box)`
  width: 100px;
  background-color: #282828;
  color: #fff;
  flex-shrink: 0;
  height: 100%;
`;

export const ContainerInner = styled(Box)`
  width: 100px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background-color: #282828;
  text-align: center;
  color: #fff;
  flex-shrink: 0;
  overflow-y: auto;
  height: 100vh;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 3px;
  }
`;

export const ListStyled = muiStyled(List)({
  "& .MuiList-root": {
    padding: 0,
  },
  "& ::-webkit-scrollbar": {
    width: "5px",
  },
  "& ::-webkit-scrollbar-thumb": {
    background: "#888",
    borderRadius: "5px",
  },
  "& .MuiTypography-root": {
    fontSize: "0.8rem",
  },
});

export const ListItemStyled = muiStyled(ListItemButton)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",

  "& .MuiListItemText-root": {
    color: "#fff",
    textAlign: "center",
  },
  "& .MuiListItemIcon-root": {
    color: "#fff",
    display: "block",
    textAlign: "center",
  },
  "& .MuiTypography-root": {
    fontSize: "0.8rem",
  },
  "& .MuiList-root": {
    padding: 0,
  },
});

export const PageLink = styled(Link)`
  color: #000;
  text-decoration: none;
  transition: 0.3;
  & .MuiButtonBase-root.MuiListItem-root {
    background-color: ${({ isselected }) => (isselected === "true" ? "rgba(113, 113, 113, 0.1)" : "rgba(0, 0, 0, 0)")};
  }
  & .MuiTypography-root {
    font-weight: ${({ isselected }) => (isselected === "true" ? 700 : 400)};
  }
`;

export const Logo = styled.img`
  width: 100%;
  padding: 8px;
`;

export const SuperAdminLogo = styled.div`
  text-align: center;
  color: #fff;
  font-weight: 700;
  padding: 8px;
  border-bottom: 1px solid #fff;
`;
