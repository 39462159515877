import { styled, keyframes } from "@mui/system";
import { Box } from "@mui/material";
import LoginBackground from "../images/background.jpg";

export const BackgroundApp = styled(Box)(({ theme }) => ({
  // backgroundImage: `url(${LoginBackground})`,
  backgroundColor: "#282828",
  height: "100vh",
  width: "100%",
  display: "flex",
  // overflow: "hidden",
  // boxSizing: "border-box",
  // backgroundPosition: "center",
  // backgroundSize: "cover",
  // backgroundRepeat: "no-repeat",
  // "& * ": {
  //   boxSizing: "border-box",
  // },
  // // display: "flex",
  // justifyContent: "center",
}));

export const Container = styled(Box)(({ theme }) => ({
  // boxSizing: "border-box",
  // display: "flex",
  // justifyContent: "center",
  // alignItems: "center",
  width: "100%",
  // height: "100%",
  // position: "relative",
  // overflow: "auto",
  // backgroundColor: "rgba(40, 40, 40, 0.95)",
  overflowY: "auto",
}));
