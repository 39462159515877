/* eslint-disable react/react-in-jsx-scope */
import { getProvidersExpertise, removeProviderExpertise, addProviderExpertise, updateProviderExpertise } from "../services/providersExpertise";
import DataTable from "../components/Table/DataTable";
import Tooltip from "../components/Utils/Tooltip";
import { roles } from "../utils/roles";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: true,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    isRequired: true,
    filterable: false,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
];

const entity = {
  tableId: "providersExpertise",
  label: "provider expertise",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.SUPERADMIN],
  create: [roles.SUPERADMIN],
  update: [roles.SUPERADMIN],
};

export default function ProvidersPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getProvidersExpertise} addData={addProviderExpertise} deleteData={removeProviderExpertise} updateData={updateProviderExpertise} headCells={headCells} />;
}
