import styled from "styled-components";
import { styled as styledMui } from "@mui/system";
import { Link, Typography, Box } from "@mui/material";

export const ContainerInner = styledMui(Box)`
    pointer-events: none
`;

export const Logo = styled.img`
  width: 50%;
  max-width: 500px;
`;

export const Canvas = styled.canvas`
  width: 100%;
  height: 100%;
`;

export const RedirectCta = styled(Link)`
  color: #fff !important;
  bottom: 0;
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 1;
  text-underline-offset: 1px;
  padding: 16px;
`;

export const GroupText = styledMui(Typography)`
  color: #fff;
  font-size: 6vw;

  @media (min-width: 1920px) {
    font-size: 90px;
  }
`;
