/* eslint-disable no-undef */
import { useState, useEffect } from "react";
import { getPreviewLinkList } from "../services/previews";
import { useLocation } from "react-router-dom";
import CryptoJS from "crypto-js";
import OresLogo from "../images/ores-white.svg";
import KindaiLogo from "../images/kindai.png";
import Logo140 from "../images/140.png";
import SusanMooreLogo from "../images/susanmoore.png";
import GhostLogo from "../images/ghosts.png";
import EkipBLogo from "../images/ekipb.png";
import BandOriginaleLogo from "../images/bandoriginale.svg";
import DigitalProdLogo from "../images/digitalProd-logo.svg";

import { Email as EmailIcon, Launch as LaunchIcon } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { useAuth } from "../hooks/use-auth";
import { Logo, ContainerProjects, ProjectLink, ContainerText, Container, LogoAgency, ProjectVisual, Visual } from "./styles/Preview.styled";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../app/feedbackSlice";
import { getTextColorForBackground, getAverageColorFromBorders } from "../utils/functions";

const ImageCard = ({ project }) => {
  const visualPath = `${process.env.REACT_APP_PROXY}/assets/clients/${project.clientVisualPath}`;
  // const [bgColor, setBgColor] = useState("#161616");
  // const [textColor, setTextColor] = useState("#fff");

  // const getColors = async () => {
  //   const backgroundColor = await getAverageColorFromBorders(visualPath);
  //   const color = getTextColorForBackground(backgroundColor);
  //   setBgColor(backgroundColor);
  //   setTextColor(color);
  // };

  // useEffect(() => {
  //   getColors();
  // }, [visualPath]);

  return (
    // <ProjectLink href={project.projectPreviewLink} target="_blank" visualPath={visualPath} $color={textColor}>
    <ProjectLink href={project.projectPreviewLink} target="_blank" visualPath={visualPath}>
      <ContainerText>
        <div>
          {project.clientName && <Typography sx={{ fontWeight: "bold" }}>{project.clientName}</Typography>}
          {project.projectName && <Typography>{project.projectName}</Typography>}
        </div>
        <LaunchIcon />
      </ContainerText>
      {/* {!!visualPath && <ProjectVisual visualPath={visualPath} $backgroundColor={bgColor} />} */}
      {!!visualPath && (
        <ProjectVisual>
          <Visual src={visualPath} alt={project.clientName} />
        </ProjectVisual>
      )}
    </ProjectLink>
  );
};

export default function PreviewsPage() {
  const [data, setData] = useState([]);
  const [agency, setAgency] = useState(null);
  const location = useLocation();
  const dispatch = useDispatch();

  const getPreviewList = async () => {
    const searchProjectsParams = new URLSearchParams(location.search).get("id");
    if (!searchProjectsParams) return;
    try {
      // const securedHash = generateSecureHash()

      const fetchedPreviewLinkList = await getPreviewLinkList(searchProjectsParams);
      const { previewLinkList, userAgency } = fetchedPreviewLinkList;
      setData(previewLinkList);

      if (userAgency) {
        setAgency(userAgency);
      }
    } catch (error) {
      console.error(error.message);
      setData([]);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  useEffect(() => {
    getPreviewList();
  }, []);

  // const getBodyShare = () => {
  //   const body = data
  //     .map(({ clientName, projectName, projectPreviewLink }) => {
  //       return `${clientName} - ${projectName} \n${projectPreviewLink}`;
  //     })
  //     .join("\n\n");
  //   return encodeURIComponent(body);
  // };

  return (
    <Container width="100%" display="flex" flexDirection="column" alignItems="center">
      <>
        <Box display="flex" flexDirection="column" alignItems="center" p={4}>
          {!!agency && <Logo src={`/api/assets/agencies/${agency.imgPath}`} alt={agency.name} />}
        </Box>
        <ContainerProjects gap={2} p={2} display="flex" justifyContent="center" width="100%" flexWrap="wrap">
          {data.map((project) => {
            return <ImageCard key={`project-preview-${project.projectId}`} project={project} />;
          })}
        </ContainerProjects>
      </>

      {/* {userType === "TEST" && !!userType && (
        <Stack sx={{ maxWidth: "500px", width: "100%", margin: "0 auto", marginTop: "50px", textAlign: "center" }} direction="row" spacing={2}>
          <Link href={`mailto:?subject=Digital Prod - Liens de preview&body=${getBodyShare()}`} underline="none" rel="noopener" target="_blank" sx={{ width: "100%", display: "block" }}>
            <Button sx={{ color: "#fff", borderColor: "#fff", width: "fit-content" }} variant="outlined" endIcon={<EmailIcon />}>
              Partager par mail
            </Button>
          </Link>
        </Stack>
      )} */}
      <Box p={1} bgcolor="#282828" width="100%" display="flex" justifyContent="center" alignItems="center" rowGap={2} columnGap={4} mt="auto" pt={4} flexWrap="wrap">
        <a href="https://www.susanmoore.fr/home" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/susanmoore.png`} alt="Susan Moore" width="200px" />
        </a>
        <a href="https://www.ores-group.com/?lang=fr" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/ores-white.svg`} alt="ORÈS" width="100px" />
        </a>
        <a href="https://kindai.fr/" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/kindai.png`} alt="Kindai" width="50px" />
        </a>
        <a href="https://digitalprod.com/" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/logo-digitalprod.svg`} alt="Digital Prod" width="200px" />
        </a>
        <a href="https://band-originale.com/" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/bandoriginale.svg`} alt="Band Originale" width="100px" />
        </a>
        <a href="https://www.ghostsplaymusic.com/" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/ghost.png`} alt="Ghost" width="100px" />
        </a>
        <a href="https://ekipb.webflow.io/" target="_blank">
          <LogoAgency src={`${process.env.REACT_APP_PROXY}/assets/agencies/ekipb.png`} alt="Ekip B" width="120px" />
        </a>
      </Box>
    </Container>
  );
}
