import { useState } from "react";
import { Box, TextField, Typography, Stack, InputAdornment } from "@mui/material";
import { AccountCircle, Password as PasswordIcon } from "@mui/icons-material";
import { Form, LoginButton, Title, Logo } from "./styles/Login.styled";
import { useAuth } from "../hooks/use-auth";
import { useDispatch } from "react-redux";
import { displayErrorFeedback } from "../app/feedbackSlice";
import oresLogoPath from "../images/ores-white.svg";

export default function LoginPage() {
  const [loginData, setLoginData] = useState({});
  const { onLogin } = useAuth();
  const dispatch = useDispatch();

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      await onLogin(loginData);
    } catch (e) {
      console.error(e);
      dispatch(displayErrorFeedback({ message: e.message }));
    }
  };

  const handleChange = (dataName, { target: { value } }) => {
    setLoginData({ ...loginData, [dataName]: value });
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" width="100%" height="100%" background="background: radial-gradient(circle,  rgba(20, 20, 20, 0.95), rgba(50, 50, 50, 0.95))">
      <Box>
        <Logo src={oresLogoPath} alt="Orès logo" />
        <Title component="h1">ORÈS GROUP</Title>
        <Form onSubmit={handleSubmit}>
          <Stack spacing={2} alignItems="center">
            <Typography fontWeight={700} fontSize="30px">
              GROUP
            </Typography>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircle />
                  </InputAdornment>
                ),
              }}
              variant="standard"
              id="username"
              label="Username"
              value={loginData.username}
              onChange={handleChange.bind(this, "username")}
              margin="dense"
              name="username"
              autocomplete="username"
            />
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon />
                  </InputAdornment>
                ),
              }}
              id="password"
              label="Password"
              type="password"
              variant="standard"
              value={loginData.password}
              onChange={handleChange.bind(this, "password")}
              margin="dense"
              name="password"
              autocomplete="current-password"
            />
            <LoginButton type="submit" variant="contained" disableElevation>
              Login
            </LoginButton>
          </Stack>
        </Form>
      </Box>
    </Box>
  );
}
