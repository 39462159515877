import axios from "../utils/axios";

export const getCelebrations = async ({ offset, limit } = {}) => {
  let url = "/celebrations?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";

  const { data } = await axios.get(url);
  return data;
};

export const removeCelebration = async (celebrationId) => {
  const { data: removedCelebration } = await axios.delete(`/celebrations/${celebrationId}`);
  return removedCelebration;
};

export const addCelebration = async (celebration) => {
  const { data: addedCelebration } = await axios.post(`/celebrations`, {
    data: celebration,
  });
  return addedCelebration;
};

export const updateCelebration = async ({ id, ...celebrationData }) => {
  const { data: updatedCelebration } = await axios.put(`/celebrations/${id}`, {
    data: celebrationData,
  });
  return updatedCelebration;
};
