import { useState, useCallback, useEffect } from "react";
import { TextField, Checkbox, FormControlLabel, Stack, Box } from "@mui/material";
import SelectAutocomplete from "../Input/SelectAutocomplete";
import { ContainerPopover, SeeMoreFiltersButton } from "./styles/Filters.styled";
import CustomDatePicker from "../Input/DatePicker";
import { debounce, throttle, set } from "lodash";
import SelectAutocompleteFilterClient from "../Input/SelectAutocompleteFilterClient";
import "./styles/filters.css";
import { Add as AddIcon, Remove as RemoveIcon } from "@mui/icons-material";

const FieldTextInput = ({ handleFiltersList, headerName, id, value = "" }) => {
  const [inputValue, setInputValue] = useState(value);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const throttledHandleFiltersList = useCallback(
    throttle((name, value) => handleFiltersList({ [name]: value }), 300),
    [handleFiltersList]
  );

  const handleInputChange = ({ target: { value } }) => {
    setInputValue(value);
    throttledHandleFiltersList(id, value);
  };

  return <TextField size="small" variant="outlined" id={`filters-${id}`} label={headerName} value={inputValue} onChange={handleInputChange} sx={{ flex: "1 1 200px" }} />;
};

export default function BasicPopover({ headCells, setFilters, filters, handleFiltersList }) {
  const [isDisplayingAllInputs, setIsDisplayingAllInputs] = useState(false);

  const setFilterList = (id, value) => {
    if (value.length) {
      setFilters((prev) => ({ ...prev, [id]: value }));
    } else {
      const newFilter = Object.entries(filters).reduce((acc, filter) => {
        const key = filter[0];
        const filterValue = filter[1];
        if (key !== id) {
          return { ...acc, [key]: filterValue };
        }
        return acc;
      }, {});
      setFilters(newFilter);
    }
  };

  const handleChange = (id, value) => {
    setFilterList(id, value);
  };

  const handleProjectDate = (id, value) => {
    setFilters((prev) => ({ ...prev, [id]: value }));
  };

  const displaySelectFields = () => {
    const filterListFields = headCells.filter(({ isOnFilter }) => isOnFilter);
    const filterList = isDisplayingAllInputs ? filterListFields : filterListFields.slice(0, 5);
    return filterList.reduce((acc, field) => {
      if (field.type === "textField") {
        return [...acc, <FieldTextInput id={field.field} key={field.field} headerName={field.headerName} handleFiltersList={handleFiltersList} value={filters[field.field]} sx={{ minWidth: "100px", width: "100%" }} />];
      }

      if (field.type === "selectAutocomplete") {
        if (field.field === "clients") {
          return [
            ...acc,
            <SelectAutocompleteFilterClient key={field.field} variant="outlined" id={field.field} limitTags={1} label={field.headerName} onChange={handleChange} fetchAction={field.fetchAction} fieldValue={filters[field.field]} handleFiltersList={handleFiltersList}></SelectAutocompleteFilterClient>,
          ];
        }
        return [...acc, <SelectAutocomplete key={field.field} variant="outlined" id={field.field} limitTags={1} label={field.headerName} onChange={handleChange} fetchAction={field.fetchAction} fieldValue={filters[field.field]}></SelectAutocomplete>];
      }
      if (field.type === "dateTime") {
        return [
          ...acc,
          <CustomDatePicker variant="outlined" key={field.field + "-from"} label="Date from" id={field.field + "From"} onChange={handleProjectDate} dateValue={filters.dateFrom || null} />,
          <CustomDatePicker variant="outlined" key={field.field + "-to"} label="Date to" id={field.field + "To"} onChange={handleProjectDate} dateValue={filters.dateTo || null} />,
        ];
      }
      if (field.type === "checkbox") {
        return [
          ...acc,
          <FormControlLabel
            key={field.field}
            sx={{ border: "solid 1px rgba(0, 0, 0, 0.23)", color: "rgba(0, 0, 0, 0.87)", height: "40px", borderRadius: "4px", padding: "8.5px 14px", margin: 0 }}
            control={<Checkbox id={field.field} onChange={(event) => setFilters((prev) => ({ ...prev, [field.field]: event.target.checked }))} checked={!!filters[field.field]} sx={{ paddingLeft: 0 }} />}
            label="Top project"
          />,
        ];
      }
      return acc;
    }, []);
  };

  const onClickSeeMoreFiltersButton = () => {
    setIsDisplayingAllInputs((prev) => !prev);
  };

  const displaySeeMoreFiltersButton = () => {
    const filterListFields = headCells.filter(({ isOnFilter }) => isOnFilter);
    return (
      filterListFields.length > 5 && (
        <SeeMoreFiltersButton
          isDisplayed={!isDisplayingAllInputs}
          variant="outlined"
          startIcon={isDisplayingAllInputs ? <RemoveIcon /> : <AddIcon />}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onClickSeeMoreFiltersButton();
            }
          }}
          onClick={onClickSeeMoreFiltersButton}
        >
          {isDisplayingAllInputs ? "Less filters" : "More filters"}
        </SeeMoreFiltersButton>
      )
    );
  };

  return (
    <div className="container-filters">
      <ContainerPopover sx={{ borderColor: "primary.main" }} id={"filters"}>
        <Box display="flex" justifyContent="flex-start" alignItems="center" flexWrap="wrap" gap={1} width="100%">
          {displaySelectFields()}
          {displaySeeMoreFiltersButton()}
        </Box>
      </ContainerPopover>
    </div>
  );
}
