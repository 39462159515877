import axios from "../utils/axios";
import { buildFilterQueryString } from "../utils/query";

export const getTypologies = async ({ offset, limit, filters } = {}) => {
  const filterQueryString = buildFilterQueryString(filters);
  let url = "/typologies?";
  url += limit !== undefined ? `limit=${limit}&` : "";
  url += offset !== undefined ? `offset=${offset}&` : "";
  url += filterQueryString;
  const { data } = await axios.get(url);
  return data;
};

export const removeTypology = async (typologyId) => {
  const { data: removedTypology } = await axios.delete(`/typologies/${typologyId}`);
  return removedTypology;
};

export const addTypology = async (typology) => {
  const { data: addedTypology } = await axios.post(`/typologies`, {
    data: typology,
  });
  return addedTypology;
};

export const updateTypology = async ({ id, ...typologyData }) => {
  const { data: updatedTypology } = await axios.put(`/typologies/${id}`, {
    data: typologyData,
  });
  return updatedTypology;
};
