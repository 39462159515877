import { useState, useEffect, forwardRef } from "react";
import { TransitionGroup } from "react-transition-group";
import PropTypes from "prop-types";
import { Collapse, Dialog, DialogContent, DialogTitle, Slide, IconButton, Typography, AccordionActions, TextField, Chip, Accordion, AccordionDetails, Tooltip } from "@mui/material";
import {
  Edit as EditIcon,
  ExpandMore as ExpandMoreIcon,
  Close as CloseIcon,
  Clear as ClearIcon,
  Search as SearchIcon,
  Business as BusinessIcon,
  DeveloperBoard as DeveloperBoardIcon,
  People as PeopleIcon,
  Hail as HailIcon,
  AccountTree as AccountTreeIcon,
  Celebration as CelebrationIcon,
  Loyalty as LoyaltyIcon,
  CheckCircleOutline as CheckCircleOutlineIcon,
  Today as DateFromIcon,
  Event as DateToIcon,
  PermMedia as PermMediaIcon,
} from "@mui/icons-material";
import { removeSearchById, getSearches, updateSearchTitle } from "../../services/searches";
import { IconButtonStyled } from "./style/Popin.styled";
import { TitleEditorContainer, SearchValidButtonContainer, FilterInfoContainer, FilterInfo, AccordionSummaryStyled, PopinTitle } from "./style/PopinSavedSearch.styled";
import { useDispatch } from "react-redux";
import { displaySuccessFeedback, displayErrorFeedback } from "../../app/feedbackSlice";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SavedSearchFilters = ({ searchData: { clients, celebrations, sectors, sales, typologies, providers, projectName, dateFrom, dateTo } }) => {
  const getList = (data) => data.map(({ name }) => name).join(", ");
  return (
    <>
      {!!projectName && (
        <FilterInfoContainer>
          <Chip size="small" icon={<PermMediaIcon />} label="Project name" variant="outlined" />
          <FilterInfo>{projectName}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!clients.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<PeopleIcon />} label="Client" variant="outlined" />
          <FilterInfo>{getList(clients)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!sales.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<LoyaltyIcon />} label="Sales" variant="outlined" />
          <FilterInfo>{getList(sales)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!sectors.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<BusinessIcon />} label="Sectors" variant="outlined" />
          <FilterInfo>{getList(sectors)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!typologies.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<DeveloperBoardIcon />} label="Typologies" variant="outlined" />
          <FilterInfo>{getList(typologies)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!celebrations.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<CelebrationIcon />} label="Celebrations" variant="outlined" />
          <FilterInfo>{getList(celebrations)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!providers.length && (
        <FilterInfoContainer>
          <Chip size="small" icon={<HailIcon />} label="Providers" variant="outlined" />
          <FilterInfo>{getList(providers)}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!dateFrom && (
        <FilterInfoContainer>
          <Chip size="small" icon={<DateFromIcon />} label="Date from" variant="outlined" />
          <FilterInfo>{new Date(dateFrom).toLocaleDateString()}</FilterInfo>
        </FilterInfoContainer>
      )}
      {!!dateTo && (
        <FilterInfoContainer>
          <Chip size="small" icon={<DateToIcon />} label="Date to" variant="outlined" />
          <FilterInfo>{new Date(dateTo).toLocaleDateString()}</FilterInfo>
        </FilterInfoContainer>
      )}
    </>
  );
};

SavedSearchFilters.propTypes = {
  searchData: PropTypes.object.isRequired,
};

const SavedSearchItem = ({ search, getFilteredSearch, deleteSavedSearch, expanded, handleChange, setFetchedSavedSearchList, fetchedSavedSearchList }) => {
  const { id, title } = search;
  const [searchTitle, setSearchTitle] = useState("");
  const [hasInputError, setHasInputError] = useState(false);
  const [isTitleEditorVisible, setIsTitleEditorVisible] = useState(false);
  const [hasSuccessUpdate, setHasSuccessUpdate] = useState(false);
  const dispatch = useDispatch();

  const toggleTitleEditorVisibility = () => {
    setIsTitleEditorVisible(!isTitleEditorVisible);
  };

  const handleInputChange = ({ target: { value } }) => {
    const isCorrect = /^[a-z ,.'-]+$/g.test(value);
    setHasInputError(!isCorrect);
    setSearchTitle(value);
  };

  const onClickValidInput = async () => {
    try {
      const updatedSearch = await updateSearchTitle(id, searchTitle);
      const fetchedData = await getSearches();
      setFetchedSavedSearchList(fetchedData);
      dispatch(displaySuccessFeedback({ message: updatedSearch.message || "Success." }));

      setHasSuccessUpdate(true);
      setHasInputError(false);

      setTimeout(() => {
        setSearchTitle("");
        setIsTitleEditorVisible(false);
        setHasSuccessUpdate(false);
      }, 2000);
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  return (
    <Accordion expanded={expanded === String(id)} onChange={handleChange(String(id))} disableGutters>
      <AccordionSummaryStyled expanded={expanded ? String(id) : "undefined"} isopen={Number(expanded === id)} expandIcon={<ExpandMoreIcon />} aria-controls={"panel" + id + "bh-content"}>
        <Typography>{title}</Typography>
      </AccordionSummaryStyled>
      <AccordionDetails id={"panel" + id + "bh-content"}>
        <SavedSearchFilters searchData={search} />
      </AccordionDetails>
      <AccordionActions>
        <Tooltip title="Apply filters">
          <IconButton color="primary" aria-label="Apply filters" onClick={getFilteredSearch.bind(null, search)}>
            <SearchIcon />
          </IconButton>
        </Tooltip>
        <TitleEditorContainer isvisible={isTitleEditorVisible}>
          <SearchValidButtonContainer isdisplayed={searchTitle.length} isvisible={isTitleEditorVisible} hassuccess={hasSuccessUpdate}>
            <Tooltip title="Validate new search name">
              <IconButton aria-label="Validate new search name" onClick={onClickValidInput}>
                <CheckCircleOutlineIcon color="success" />
              </IconButton>
            </Tooltip>
            <Typography>Successfully updated!</Typography>
          </SearchValidButtonContainer>
          <TextField error={hasInputError} id="search-name-input" placeholder="Update search name" variant="standard" size="small" required value={searchTitle} onChange={handleInputChange} />
        </TitleEditorContainer>
        <Tooltip title={isTitleEditorVisible ? "Cancel search title" : "Edit search title"}>
          <IconButton color="primary" aria-label={isTitleEditorVisible ? "Cancel search title" : "Edit search title"} onClick={toggleTitleEditorVisibility}>
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete search">
          <IconButton color="primary" aria-label="Delete search" onClick={deleteSavedSearch.bind(null, search.id)}>
            <ClearIcon />
          </IconButton>
        </Tooltip>
      </AccordionActions>
    </Accordion>
  );
};

SavedSearchItem.propTypes = {
  search: PropTypes.object.isRequired,
  getFilteredSearch: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func.isRequired,
  expanded: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFetchedSavedSearchList: PropTypes.func.isRequired,
  fetchedSavedSearchList: PropTypes.array.isRequired,
};

const SavedSearchList = ({ fetchedSavedSearchList, getFilteredSearch, deleteSavedSearch, setFetchedSavedSearchList }) => {
  const [expanded, setExpanded] = useState("false");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(String(isExpanded && panel));
  };

  const getSavedSearchItemList = () => {
    return fetchedSavedSearchList.map((search, index) => {
      return (
        <Collapse key={index}>
          <SavedSearchItem expanded={expanded} setFetchedSavedSearchList={setFetchedSavedSearchList} fetchedSavedSearchList={fetchedSavedSearchList} search={search} getFilteredSearch={getFilteredSearch} deleteSavedSearch={deleteSavedSearch} handleChange={handleChange} />
        </Collapse>
      );
    });
  };

  return <TransitionGroup>{getSavedSearchItemList()}</TransitionGroup>;
};

SavedSearchList.propTypes = {
  fetchedSavedSearchList: PropTypes.array.isRequired,
  getFilteredSearch: PropTypes.func.isRequired,
  deleteSavedSearch: PropTypes.func.isRequired,
  setFetchedSavedSearchList: PropTypes.func.isRequired,
};

export default function SavedSearchPopin({ isOpen, handleClose, setFilters }) {
  const [fetchedSavedSearchList, setFetchedSavedSearchList] = useState([]);
  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      const fetchedData = await getSearches();
      setFetchedSavedSearchList(fetchedData);
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  const getFilteredSearch = (selectedSearch) => {
    setFilters({ ...selectedSearch, name: selectedSearch.projectName });
    handleClose();
  };

  const deleteSavedSearch = async (searchId) => {
    try {
      const removedSearch = await removeSearchById(searchId);
      const fetchedData = await getSearches();
      setFetchedSavedSearchList(fetchedData);
      dispatch(displaySuccessFeedback({ message: removedSearch.message || "Success." }));
    } catch (error) {
      console.error(error.message);
      dispatch(displayErrorFeedback({ message: error.message || "Oops, an error occurred..." }));
    }
  };

  useEffect(() => {
    if (isOpen) {
      fetchData();
    }
  }, [isOpen]);

  return (
    <Dialog open={isOpen} TransitionComponent={Transition} keepMounted onClose={handleClose} aria-describedby="saved-search-popin__title" fullWidth={true} maxWidth="sm">
      <PopinTitle>
        <Typography>My saved searches</Typography>
        <IconButtonStyled aria-label="Close popin" onClick={handleClose}>
          <CloseIcon />
        </IconButtonStyled>
      </PopinTitle>
      <DialogContent dividers>
        {fetchedSavedSearchList.length ? <SavedSearchList fetchedSavedSearchList={fetchedSavedSearchList} getFilteredSearch={getFilteredSearch} deleteSavedSearch={deleteSavedSearch} setFetchedSavedSearchList={setFetchedSavedSearchList} /> : <p>No saved search</p>}
      </DialogContent>
    </Dialog>
  );
}

SavedSearchPopin.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
};
