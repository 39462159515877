import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { Box, Popover, Badge, IconButton, Typography, colors, AccordionSummary, Accordion, DialogTitle } from "@mui/material";

export const ContainerProject = muiStyled(Box)(() => ({
  // display: "flex",
  // justifyContent: "space-between",
  // alignItems: "center",
}));

export const ContainerProjectMenu = muiStyled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const ContainerProjectDetail = muiStyled(Box)(({ isExpended }) => ({
  overflow: "hidden",
  maxHeight: isExpended ? "500px" : "0",
  transition: "max-height 0.3s",
  backgroundColor: "#ededed",
}));

export const ContainerFilterName = muiStyled(Typography)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px",
}));

/***/

// export const AccordionSummaryStyled = styled(AccordionSummary)`
//   ${"" /* display */}
// `;

// &:not(:first-child) {
//   margin-top: 10px;
// }

export const TitleEditorContainer = styled.div`
  max-width: ${({ isvisible }) => (isvisible ? "100%" : 0)};
  transition: max-width 0.3s;
  overflow: hidden;
  position: relative;

  .MuiInputBase-input {
    padding-right: 40px;
  }
`;

export const SearchValidButtonContainer = styled.div`
  position: absolute;
  left: ${({ isdisplayed, isvisible, hassuccess }) => {
    if (hassuccess) {
      return 0;
    }
    if (isdisplayed && isvisible) {
      return "calc(100% - 40px)";
    }
    return "100%";
  }};
  top: 50%;
  transform: translateY(-50%);
  opacity: ${({ isdisplayed, isvisible }) => (isdisplayed && isvisible ? 1 : 0)};
  transition: left 0.3s, opacity 0.2s, transform 0.3s;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #fff;
  width: 100%;
  opacity: 1;

  .MuiButtonBase-root {
    transform: ${({ isdisplayed, isvisible }) => (isdisplayed && isvisible ? "rotate(0deg)" : "rotate(180deg)")};
    transition: transform 0.3s;
  }

  .MuiTypography-root {
    white-space: nowrap;
    font-size: 0.85em;
    color: #2e7d32;
    font-weight: 700;
  }
`;

export const FilterInfoContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
`;

export const FilterInfo = muiStyled(Typography)(() => ({
  paddingLeft: "15px",
}));

export const AccordionSummaryStyled = muiStyled(AccordionSummary)(({ isopen }) => ({
  backgroundColor: isopen ? "#eee" : "#fff",
}));

export const PopinTitle = muiStyled(DialogTitle)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});
