/* eslint-disable react/react-in-jsx-scope */
import { getProviders, removeProvider, addProvider, updateProvider } from "../services/providers";
import DataTable from "../components/Table/DataTable";
import { getProvidersExpertise } from "../services/providersExpertise";
import Tooltip from "../components/Utils/Tooltip";
import { roles } from "../utils/roles";
import { getAgencies } from "../services/agencies";

const headCells = [
  {
    field: "name",
    headerName: "Name",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    isOnFilter: true,
    valueGetter: (params) => params.row.name,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
    isRequired: true,
    helperText: "Please provide a name.",
  },
  {
    field: "expertiseList",
    headerName: "Providers' expertise",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "selectAutocomplete",
    isSelectMultiple: true,
    flex: 1,
    filterable: true,
    isOnFilter: true,
    valueGetter: (params) => params.row.expertiseList?.map((s) => s.name).join(", "),
    fetchAction: async () => await getProvidersExpertise(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "email",
    headerName: "Email",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.email,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "phoneNumber",
    headerName: "Phone number",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.phoneNumber,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "note",
    headerName: "Note",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.note,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "price",
    headerName: "Price",
    sortable: false,
    editable: false,
    isOnTable: true,
    type: "textField",
    flex: 1,
    filterable: false,
    valueGetter: (params) => params.row.price,
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
  {
    field: "agencies",
    headerName: "Agency",
    sortable: true,
    editable: false,
    isOnTable: false,
    isOnEditPopin: false,
    isSuperAdminEditOnly: true,
    type: "selectAutocomplete",
    isSelectMultiple: false,
    flex: 1,
    filterable: true,
    maxWidth: 150,
    isOnFilter: false,
    valueGetter: (params) => params.row.agencies?.[0]?.name,
    fetchAction: async () => await getAgencies(),
    renderCell: (params) => {
      return <Tooltip title={params.value} />;
    },
  },
];

const entity = {
  tableId: "providers",
  label: "provider",
};

const permissions = {
  read: [roles.ALL],
  delete: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
  create: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
  update: [roles.PM, roles.SALE, roles.ADMIN, roles.SUPERADMIN],
};

export default function ProvidersPages() {
  return <DataTable permissions={permissions} entity={entity} fetchData={getProviders} addData={addProvider} deleteData={removeProvider} updateData={updateProvider} headCells={headCells} />;
}
