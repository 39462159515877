import styled from "styled-components";
import { styled as muiStyled } from "@mui/material/styles";
import { Typography, Divider, IconButton, ListItem } from "@mui/material";

export const DividerStyled = styled(Divider)`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TypographyTitleStyled = styled(Typography)`
  font-weight: bold;
`;

export const TypographySubTitleStyled = styled(Typography)`
  font-weight: bold;
  font-size: 0.85em;
  opacity: 0.7;
`;

export const IconButtonStyled = styled(IconButton)`
  position: absolute;
  right: 8px;
  top: 8px;
`;

export const ListItemBlockStyled = styled(ListItem)`
  flex-direction: column;
  align-items: flex-start;
`;

export const VisuallyHiddenInput = muiStyled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export const ImagePreview = styled.img`
  width: 200px;
  height: 100px;
  object-fit: contain;
`;

export const ImagePreviewContainer = styled.div`
  width: 300px;
  border: solid 1px #eee;
  overflow: hidden;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;
